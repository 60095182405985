/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
.PTSansNarrow {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 400 !important;
}
.PTSansNarrowBold {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
}
@font-face {
  font-family: 'Bjola';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.svg#bjola') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Bjola {
  font-family: Bjola;
  font-weight: normal !important;
}
html {
  font-size: 16px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.textContent {
  font-size: 1rem;
}
.textContent h1,
.lower-content h2 {
  font-family: Bjola;
  font-weight: normal !important;
  color: #f05a28;
  text-transform: uppercase;
  font-size: 2.125rem;
}
@media (max-width: 767px) {
  .textContent h1,
  .lower-content h2 {
    margin-top: 0;
  }
}
/*#endregion fonts*/
form {
  max-width: 100vw;
  overflow: hidden;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
}
.top-wrapper {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #bae9ff), to(#fff));
  background: linear-gradient(to bottom, #bae9ff 0, #fff 100%);
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1430px) {
  .container {
    width: 1400px;
  }
}
.container--content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #e5152b;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
.header {
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .header {
    padding: 80px 5% 30px;
  }
}
@media (min-width: 768px) {
  .header {
    height: 303px;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 380px;
    left: 5px;
  }
}
.header__call {
  color: #eb008b;
  font-family: Bjola;
  font-weight: normal !important;
  font-size: 1.875rem;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .header__call {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .header__call {
    position: absolute;
    right: 10px;
    bottom: 118px;
    width: 470px;
  }
}
@media (max-width: 767px) {
  .header__phone {
    display: block;
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    right: 10px;
    width: 466px;
    bottom: 37px;
  }
}
.header #BCNLink {
  max-width: 150px;
  width: 150px;
  height: 50px;
}
@media (max-width: 767px) {
  .header #BCNLink {
    display: block;
    margin: 20px auto 0;
  }
}
@media (min-width: 768px) {
  .header #BCNLink {
    position: absolute;
    bottom: 183px;
    right: 79px;
  }
}
@media (max-width: 767px) {
  .header__social-links {
    margin-top: 20px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    bottom: 187px;
    right: 240px;
  }
}
.header__social-link {
  width: 45px;
  margin: 0 3px;
}
.header__social-link img {
  width: 45px;
  height: 48px;
}
.header__cloud {
  display: none;
  position: absolute;
  width: 15%;
}
@media (min-width: 1200px) {
  .header__cloud {
    display: block;
  }
}
.header__cloud--01 {
  left: -18%;
  top: 220px;
}
.header__cloud--02 {
  left: 30%;
  top: 70px;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
}
.header__cloud--03 {
  left: 50%;
  top: 70px;
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
}
.header__cloud--04 {
  right: -18%;
  top: 200px;
  -webkit-animation-delay: -6s;
          animation-delay: -6s;
}
.toplinks {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/red-bar-bg.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.toplinks__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 22px 5px;
  color: #fff;
  font-size: 1.425rem;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.35);
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #fff000;
}
.toplinks__dropdown {
  padding: 0;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background: #e5152b;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 5px 10px !important;
  font-size: 1.425rem;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  color: #fff !important;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.35);
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: inherit !important;
  color: #fff000 !important;
}
.home-gallery {
  position: relative;
  margin-top: 25px;
}
@media (min-width: 768px) {
  .home-gallery {
    padding: 0 70px;
  }
}
.home-gallery__arrow {
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  background-size: 100% 100%;
  top: 50%;
  margin-top: -25px;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/032/home-gallery-arrow.png");
}
.home-gallery__arrow--prev {
  left: 0;
}
.home-gallery__arrow--next {
  right: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.home-gallery__slide {
  margin: 0 8px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 5px;
}
.home-gallery .DetailsThumbnail.thumbnail {
  padding: 100% 0 0;
  border: none;
  margin: 0;
}
.home-gallery .DetailsThumbnail.thumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.columns {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.columns__left {
  display: none;
  padding-bottom: 50px;
}
@media (min-width: 992px) {
  .columns__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 355px;
  }
}
.columns__main {
  padding-bottom: 50px;
}
@media (min-width: 992px) {
  .columns__main {
    width: calc(100% - 385px);
  }
}
@media (min-width: 992px) {
  .banner {
    padding-left: 10px;
  }
}
.banner__bg {
  padding: 2% 4% 2.5% 2%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/banner-bg.svg");
  background-size: 100% 100%;
}
.banner__inner #CarouselContainer {
  padding-top: 42.595%;
  overflow: hidden;
}
.banner__inner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner__inner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner__inner #CarouselContainer .carousel-inner .item a img,
.banner__inner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner__inner img {
  max-width: none !important;
  width: 100%;
}
.banner .carousel-inner {
  border-radius: 20px;
}
.banner #CarouselContainer {
  overflow: visible !important;
}
.banner #CarouselPrevious,
.banner #CarouselNext {
  width: 3%;
  position: absolute;
  top: 50%;
}
.banner #CarouselPrevious:before,
.banner #CarouselNext:before {
  content: "";
  display: block;
  padding-top: 200%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/banner-arrow.svg");
  background-size: 100% 100%;
}
.banner #CarouselPrevious {
  -webkit-transform: translate3d(-150%, -50%, 0) scaleX(-1);
          transform: translate3d(-150%, -50%, 0) scaleX(-1);
  left: 0;
}
.banner #CarouselNext {
  -webkit-transform: translate3d(180%, -50%, 0);
          transform: translate3d(180%, -50%, 0);
  right: 0;
}
.featured-galleries {
  margin-top: 30px;
}
@media (max-width: 420px) {
  .featured-galleries .col-xs-4 {
    width: 100%;
  }
  .featured-galleries .col-xs-4:nth-child(3n + 2) {
    margin: 20px auto;
  }
}
.category-links {
  margin-bottom: 30px;
}
.category-links__link,
.category-links__child-link {
  position: relative;
  padding: 0 20px;
  text-align: center;
  min-height: 85px;
  font-family: Bjola;
  font-weight: normal !important;
  line-height: 1.15;
  text-transform: uppercase;
  font-size: 1.5525rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  -webkit-transform: translate3d(1px, 1px, 0);
          transform: translate3d(1px, 1px, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  text-shadow: 0px 0px 0px #000, -0.75px -0.75px 0px #000, 0px -0.75px 0px #000, 0.75px -0.75px 0px #000, 0.75px 0px 0px #000, 0.75px 0.75px 0px #000, 0px 0.75px 0px #000, -0.75px 0.75px 0px #000, -0.75px 0px 0px #000, -1.5px -1.5px 0px #000, -0.75px -1.5px 0px #000, 0px -1.5px 0px #000, 0.75px -1.5px 0px #000, 1.5px -1.5px 0px #000, 1.5px -0.75px 0px #000, 1.5px 0px 0px #000, 1.5px 0.75px 0px #000, 1.5px 1.5px 0px #000, 0.75px 1.5px 0px #000, 0px 1.5px 0px #000, -0.75px 1.5px 0px #000, -1.5px 1.5px 0px #000, -1.5px 0.75px 0px #000, -1.5px 0px 0px #000, -1.5px -0.75px 0px #000;
}
.category-links__link:before,
.category-links__child-link:before,
.category-links__link:after,
.category-links__child-link:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}
.category-links__link:before,
.category-links__child-link:before {
  -webkit-transform: translate3d(-1px, -1px, 0);
          transform: translate3d(-1px, -1px, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link:focus,
.category-links__child-link:focus,
.category-links__link.active,
.category-links__child-link.active {
  -webkit-transform: translate3d(5px, 5px, 0);
          transform: translate3d(5px, 5px, 0);
  color: #fff;
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before,
.category-links__link.active:before,
.category-links__child-link.active:before {
  -webkit-transform: translate3d(-5px, -5px, 0);
          transform: translate3d(-5px, -5px, 0);
}
.category-links__link:nth-of-type(8n + 1):before,
.category-links__child-link:nth-of-type(8n + 1):before {
  -webkit-filter: drop-shadow(6px 6px 0 #f8ad02);
          filter: drop-shadow(6px 6px 0 #f8ad02);
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-right-white.svg");
}
.category-links__link:nth-of-type(8n + 1):after,
.category-links__child-link:nth-of-type(8n + 1):after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-yellow.svg");
}
.category-links__link:nth-of-type(8n + 1) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(8n + 1) + div .category-links__child-link:before {
  -webkit-filter: drop-shadow(6px 6px 0 #f8ad02);
          filter: drop-shadow(6px 6px 0 #f8ad02);
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-right-white.svg");
}
.category-links__link:nth-of-type(8n + 1) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(8n + 1) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-yellow.svg");
}
.category-links__link:nth-of-type(8n + 2):before,
.category-links__child-link:nth-of-type(8n + 2):before {
  -webkit-filter: drop-shadow(6px 6px 0 #b00068);
          filter: drop-shadow(6px 6px 0 #b00068);
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-left-white.svg");
}
.category-links__link:nth-of-type(8n + 2):after,
.category-links__child-link:nth-of-type(8n + 2):after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-pink.svg");
}
.category-links__link:nth-of-type(8n + 2) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(8n + 2) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-left-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #b00068);
          filter: drop-shadow(6px 6px 0 #b00068);
}
.category-links__link:nth-of-type(8n + 2) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(8n + 2) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-pink.svg");
}
.category-links__link:nth-of-type(8n + 3):before,
.category-links__child-link:nth-of-type(8n + 3):before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-right-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #75003f);
          filter: drop-shadow(6px 6px 0 #75003f);
}
.category-links__link:nth-of-type(8n + 3):after,
.category-links__child-link:nth-of-type(8n + 3):after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-purple.svg");
}
.category-links__link:nth-of-type(8n + 3) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(8n + 3) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-right-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #75003f);
          filter: drop-shadow(6px 6px 0 #75003f);
}
.category-links__link:nth-of-type(8n + 3) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(8n + 3) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-purple.svg");
}
.category-links__link:nth-of-type(8n + 4):before,
.category-links__child-link:nth-of-type(8n + 4):before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-left-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #b00007);
          filter: drop-shadow(6px 6px 0 #b00007);
}
.category-links__link:nth-of-type(8n + 4):after,
.category-links__child-link:nth-of-type(8n + 4):after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-red.svg");
}
.category-links__link:nth-of-type(8n + 4) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(8n + 4) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-left-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #b00007);
          filter: drop-shadow(6px 6px 0 #b00007);
}
.category-links__link:nth-of-type(8n + 4) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(8n + 4) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-red.svg");
}
.category-links__link:nth-of-type(8n + 5):before,
.category-links__child-link:nth-of-type(8n + 5):before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-right-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #0e9323);
          filter: drop-shadow(6px 6px 0 #0e9323);
}
.category-links__link:nth-of-type(8n + 5):after,
.category-links__child-link:nth-of-type(8n + 5):after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-green.svg");
}
.category-links__link:nth-of-type(8n + 5) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(8n + 5) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-right-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #0e9323);
          filter: drop-shadow(6px 6px 0 #0e9323);
}
.category-links__link:nth-of-type(8n + 5) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(8n + 5) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-green.svg");
}
.category-links__link:nth-of-type(8n + 6):before,
.category-links__child-link:nth-of-type(8n + 6):before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-left-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #cd3706);
          filter: drop-shadow(6px 6px 0 #cd3706);
}
.category-links__link:nth-of-type(8n + 6):after,
.category-links__child-link:nth-of-type(8n + 6):after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-orange.svg");
}
.category-links__link:nth-of-type(8n + 6) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(8n + 6) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-left-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #cd3706);
          filter: drop-shadow(6px 6px 0 #cd3706);
}
.category-links__link:nth-of-type(8n + 6) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(8n + 6) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-orange.svg");
}
.category-links__link:nth-of-type(8n + 7):before,
.category-links__child-link:nth-of-type(8n + 7):before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-right-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #0069ba);
          filter: drop-shadow(6px 6px 0 #0069ba);
}
.category-links__link:nth-of-type(8n + 7):after,
.category-links__child-link:nth-of-type(8n + 7):after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-l-blue.svg");
}
.category-links__link:nth-of-type(8n + 7) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(8n + 7) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-right-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #0069ba);
          filter: drop-shadow(6px 6px 0 #0069ba);
}
.category-links__link:nth-of-type(8n + 7) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(8n + 7) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-l-blue.svg");
}
.category-links__link:nth-of-type(8n + 8):before,
.category-links__child-link:nth-of-type(8n + 8):before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-left-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #004b85);
          filter: drop-shadow(6px 6px 0 #004b85);
}
.category-links__link:nth-of-type(8n + 8):after,
.category-links__child-link:nth-of-type(8n + 8):after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-d-blue.svg");
}
.category-links__link:nth-of-type(8n + 8) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(8n + 8) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-left-white.svg");
  -webkit-filter: drop-shadow(6px 6px 0 #004b85);
          filter: drop-shadow(6px 6px 0 #004b85);
}
.category-links__link:nth-of-type(8n + 8) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(8n + 8) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/button-d-blue.svg");
}
.category-links__link:nth-child(odd),
.category-links__child-link:nth-child(odd) {
  margin-bottom: 18px;
  padding-top: 10px;
}
.category-links__link:nth-child(even),
.category-links__child-link:nth-child(even) {
  margin-bottom: 8px;
}
.category-links__child-link {
  margin-left: 30px;
}
.badges {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 20px;
  margin-bottom: 200px;
}
.badges__badge {
  -ms-flex-item-align: center;
      align-self: center;
  margin: 0 3px;
}
.badges__badge--biha {
  width: 18%;
}
.badges__badge--tipe {
  width: 18%;
}
.badges__badge--bcn {
  width: 45%;
}
.left-block {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/032/left-block-bg.png");
  text-align: center;
  background-size: 100% 100%;
  margin-top: auto;
  padding: 0 3% 24%;
}
.left-block__logo {
  margin-top: -50%;
}
.left-block__call-today {
  margin-top: 10px;
  font-family: Bjola;
  font-weight: normal !important;
  font-size: 1.7rem;
  color: #eb008b;
  text-transform: uppercase;
}
.left-block__phone,
.left-block__phone-2 {
  color: #000;
  font-family: Bjola;
  font-weight: normal !important;
  font-size: 3rem;
}
.left-block__phone:hover,
.left-block__phone-2:hover {
  color: #000;
}
.products-intro {
  background-color: #e5152b;
  border-radius: 15px;
  color: #fff;
  text-transform: uppercase;
  padding: 25px 34px 20px;
  line-height: 1;
  font-size: 2.11rem;
  font-family: Bjola;
  font-weight: normal !important;
  margin: 30px 0 0;
  text-align: center;
  border: 1px;
}
@media (max-width: 767px) {
  .products-intro {
    position: relative;
    margin: 30px 5px;
  }
  .products-intro:before {
    content: "";
    border: 2px dashed red;
    border-radius: 18px;
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
  }
}
@media (min-width: 768px) {
  .products-intro {
    background-color: none;
    border-radius: none;
    text-align: left;
    background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/red-bar-bg.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.page-listings {
  margin-top: 30px;
}
@media (max-width: 420px) {
  .page-listings > div {
    width: 100%;
  }
}
.castlePanel {
  width: 100%;
  margin: 0 0 30px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background: #b8e8ff;
  -webkit-box-shadow: 1px 2px 0 0 #8ddaff, 2px 4px 0 0 #8ddaff, 3px 6px 0 0 #8ddaff, 4px 8px 0 0 #8ddaff, 5px 10px 0 0 #8ddaff, 6px 12px 0 0 #8ddaff, 7px 14px 0 0 #8ddaff;
          box-shadow: 1px 2px 0 0 #8ddaff, 2px 4px 0 0 #8ddaff, 3px 6px 0 0 #8ddaff, 4px 8px 0 0 #8ddaff, 5px 10px 0 0 #8ddaff, 6px 12px 0 0 #8ddaff, 7px 14px 0 0 #8ddaff;
  text-align: center;
}
.castlePanel:hover .castleCheckBook {
  color: #fff000;
}
.castlePanel .SingleImagePanel {
  height: auto;
}
.castlePanel .castleTitlePanel {
  margin: 10px 0 0;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  color: #084ab1;
  font-size: 1rem;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0;
  margin-top: 10px;
  color: #484646;
  font-size: 1.25rem;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
}
.castlePanel .castleCheckBook {
  background: #e1008c;
  border-radius: 10px;
  margin-top: 10px;
  height: auto;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.35);
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 15px;
  line-height: 1;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.testimonials {
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/032/testimonials-bg-small.svg");
  background-size: 100% 100%;
  text-align: center;
  padding-top: 1px;
  margin-top: 30px;
  padding: 1px 5%;
}
@media (max-width: 767px) {
  .testimonials {
    position: relative;
    background-image: none;
    background: #ce4ec8;
    border-radius: 15px;
  }
  .testimonials:before {
    content: "";
    border: 2px dashed #ce4ec8;
    border-radius: 18px;
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
  }
}
@media (min-width: 1200px) {
  .testimonials {
    background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/032/testimonials-faces-bg.png");
    padding-left: 42%;
    padding-right: 8%;
  }
}
.testimonials__title {
  color: #fff;
  text-transform: uppercase;
  font-size: 2.11rem;
  font-family: Bjola;
  font-weight: normal !important;
}
@media (min-width: 1200px) {
  .testimonials__title {
    margin-top: 40px;
  }
}
.testimonials__inner {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .testimonials__inner {
    margin-top: 20px;
  }
}
.testimonials__inner a {
  color: #fff;
}
.testimonials__inner a:hover {
  color: #fff;
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.testimonials .RotatorTestimonialContent p:first-child {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  font-size: 1.2rem;
}
.testimonials #RotatorTestimonialsHeader {
  margin-bottom: 15px;
}
.testimonials #RotatorTestimonialsHeader p,
.testimonials #RotatorTestimonialsHeader a {
  text-align: center;
  color: #fff;
  font-size: 1rem;
  position: relative;
}
.testimonials .RotatorTestimonialRating {
  max-width: 280px;
  margin: 20px auto 0;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #e5152b;
}
.DetailsTitle h1,
.DetailsTitle h2 {
  padding: 10px;
  font-family: Bjola;
  font-weight: normal !important;
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #fff;
  text-shadow: 0px 0px 0px #000, -0.75px -0.75px 0px #000, 0px -0.75px 0px #000, 0.75px -0.75px 0px #000, 0.75px 0px 0px #000, 0.75px 0.75px 0px #000, 0px 0.75px 0px #000, -0.75px 0.75px 0px #000, -0.75px 0px 0px #000, -1.5px -1.5px 0px #000, -0.75px -1.5px 0px #000, 0px -1.5px 0px #000, 0.75px -1.5px 0px #000, 1.5px -1.5px 0px #000, 1.5px -0.75px 0px #000, 1.5px 0px 0px #000, 1.5px 0.75px 0px #000, 1.5px 1.5px 0px #000, 0.75px 1.5px 0px #000, 0px 1.5px 0px #000, -0.75px 1.5px 0px #000, -1.5px 1.5px 0px #000, -1.5px 0.75px 0px #000, -1.5px 0px 0px #000, -1.5px -0.75px 0px #000;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 180px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.footer {
  padding: 20px 0 50px;
  color: #222;
}
.footer__container {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
  border-radius: 10px;
}
.footer__bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__flex-row-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 768px) {
  .footer__text-center-sm {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__text-right-sm {
    text-align: right;
  }
}
.footer__links {
  text-align: center;
}
.footer__link {
  margin: 0 5px 10px;
  padding: 8px 0;
  color: #222;
  display: inline-block;
}
.footer__link:hover {
  color: #222;
}
.footer #BCNLink {
  color: #222;
}
.footer #BCNLink:hover {
  color: #222;
}
/*#region Animations*/
/*#endregion Animations*/
