@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@desktop: ~"(min-width: 1200px)";
@widescreen: ~"(min-width: 1430px)";
@mobileNavColor: #e5152b;
@castleDetailsColor: #e5152b;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/032/";
@cloudUrlNoAuto: "https://files.bookingonline.co.uk/image/upload/themes/032/";

/*#region fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700 !important;
}

.PTSansNarrow {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 400 !important;
}

.PTSansNarrowBold {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700 !important;
}

.font-Bjola;

.Bjola {
	font-family: Bjola;
	font-weight: normal !important;
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
}

.nicefont {
}

.textContent {
	font-size: 1rem;
}

.textContent h1, .lower-content h2 {
	.Bjola;
	color: #f05a28;
	text-transform: uppercase;
	font-size: 2.125rem;

	@media @mobile {
		margin-top: 0;
	}
}

h2, h3, h4 {
}
/*#endregion fonts*/
form {
	max-width: 100vw;
	overflow: hidden;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;

	&--alt {
	}
}

.top-wrapper {
	background: linear-gradient(to bottom, #bae9ff 0, #fff 100%);
}

.container {
	position: relative;

	@media @mobile {
		width: 100%;
	}

	@media @tablet {
		width: 100%;
	}

	@media @desktop {
		//width: 1170px;
	}

	@media @widescreen {
		width: 1400px;
	}

	&--content {
		flex-grow: 1;
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/
.header {
	text-align: center;
	position: relative;

	@media @mobile {
		padding: 80px 5% 30px;
	}

	@media @tablet {
		height: 303px;
	}

	&__logo {
		@media @tablet {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 380px;
			left: 5px;
		}
	}

	&__call {
		color: #eb008b;
		.Bjola;
		font-size: 1.875rem;
		text-transform: uppercase;

		@media @mobile {
			margin-top: 20px;
		}

		@media @tablet {
			position: absolute;
			right: 10px;
			bottom: 118px;
			width:470px;
		}
	}

	&__phone {
		@media @mobile {
			display: block;
			margin-top: 20px;
		}

		@media @tablet {
			position: absolute;
			right: 10px;
			width: 466px;
			bottom: 37px;
		}
	}

	#BCNLink {
		max-width: 150px;
		width: 150px;
		height: 50px;

		@media @mobile {
			display: block;
			margin: 20px auto 0;
		}

		@media @tablet {
			position: absolute;
			bottom: 183px;
			right: 79px;
		}
	}

	&__social-links {

		@media @mobile {
			margin-top: 20px;
			display: inline-flex;
		}

		@media @tablet {
			display: flex;
			position: absolute;
			bottom: 187px;
			right: 240px;
		}
	}

	&__social-link {
		width: 45px;
		margin: 0 3px;

		@media @tablet {
		}

		img {
			width: 45px;
			height: 48px;
		}
	}

	&__cloud {
		display: none;

		@media @desktop {
			display: block;
		}

		position: absolute;
		width: 15%;



		&--01 {
			left: -18%;
			top: 220px;
		}

		&--02 {
			left: 30%;
			top: 70px;
			animation-delay: -2s;
		}

		&--03 {
			left: 50%;
			top: 70px;
			animation-delay: -4s;
		}

		&--04 {
			right: -18%;
			top: 200px;
			animation-delay: -6s;
		}
	}
}

.toplinks {
	background-image: url("@{cloudUrlNoAuto}red-bar-bg.svg");
	background-size: 100% 100%;
	background-repeat: no-repeat;

	&__nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;
		flex-grow: 1;

		.dropdown-toggle:focus {
			text-decoration: none;
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 22px 5px;
		color: #fff;
		font-size: 1.425rem;
		.PTSansNarrowBold;
		text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.35);

		&:hover, &.active, &:focus {
			color: #fff000;
		}
	}

	&__dropdown {
		padding: 0;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		background: #e5152b;
		left: 50%;
		transform: translateX(-50%);
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 5px 10px !important;
		font-size: 1.425rem;
		.PTSansNarrowBold;
		color: #fff !important;
		text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.35);

		&:hover, &:focus, &.active {
			background-color: inherit !important;
			color: #fff000 !important;
		}
	}
}

.home-gallery {
	position: relative;
	margin-top: 25px;

	@media @sm {
		padding: 0 70px;
	}

	&__arrow {
		display: block;
		.size(50px, 50px);
		position: absolute;
		background-size: 100% 100%;
		top: 50%;
		margin-top: -25px;
		background-image: url("@{cloudUrl}home-gallery-arrow.png");

		&--prev {
			left: 0;
		}

		&--next {
			right: 0;
			transform: scaleX(-1);
		}
	}

	&__slide {
		margin: 0 8px;
		border: 1px solid #ddd;
		background: #fff;
		padding: 5px;
	}

	.DetailsThumbnail.thumbnail {
		padding: 100% 0 0;
		border: none;
		margin: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.columns {
	margin-top: 40px;

	@media @md {
		display: flex;
		justify-content: space-between;
	}

	&__left {
		display: none;
		padding-bottom: 50px;

		@media @md {
			display: flex;
			flex-direction: column;
			width: 355px;
		}
	}

	&__main {
		padding-bottom: 50px;

		@media @md {
			width: ~'calc(100% - 385px)';
		}
	}
}

.banner {
	@media @md {
		padding-left: 10px;
	}

	&__bg {
		padding: 2% 4% 2.5% 2%;
		background-image: url("@{cloudUrlNoAuto}banner-bg.svg");
		background-size: 100% 100%;
	}

	&__inner {
		.BannerPanelSet(42.595%);

		img {
			max-width: none !important;
			width: 100%;
		}
	}

	.carousel-inner {
		border-radius: 20px;
	}

	#CarouselContainer {
		overflow: visible !important;
	}

	#CarouselPrevious, #CarouselNext {
		width: 3%;
		position: absolute;
		top: 50%;

		&:before {
			content: "";
			display: block;
			padding-top: 200%;
			background-image: url("@{cloudUrlNoAuto}banner-arrow.svg");
			background-size: 100% 100%;
		}
	}

	#CarouselPrevious {
		transform: translate3d(-150%,-50%,0)scaleX(-1);
		left: 0;
	}

	#CarouselNext {
		transform: translate3d(180%,-50%,0);
		right: 0;
	}
}

.featured-galleries {
	margin-top: 30px;

	@media (max-width:420px) {
		.col-xs-4 {
			width: 100%;

			&:nth-child(3n + 2) {
				margin: 20px auto;
			}
		}
	}
}

.category-links {
	margin-bottom: 30px;

	&__link, &__child-link {
		position: relative;
		padding: 0 20px;
		text-align: center;
		min-height: 85px;
		.Bjola;
		line-height: 1.15;
		text-transform: uppercase;
		font-size: 1.5525rem;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		transform: translate3d(1px,1px,0);
		transition: transform .3s;
		text-shadow: 0px 0px 0px #000, -0.75px -0.75px 0px #000, 0px -0.75px 0px #000, 0.75px -0.75px 0px #000, 0.75px 0px 0px #000, 0.75px 0.75px 0px #000, 0px 0.75px 0px #000, -0.75px 0.75px 0px #000, -0.75px 0px 0px #000, -1.5px -1.5px 0px #000, -0.75px -1.5px 0px #000, 0px -1.5px 0px #000, 0.75px -1.5px 0px #000, 1.5px -1.5px 0px #000, 1.5px -0.75px 0px #000, 1.5px 0px 0px #000, 1.5px 0.75px 0px #000, 1.5px 1.5px 0px #000, 0.75px 1.5px 0px #000, 0px 1.5px 0px #000, -0.75px 1.5px 0px #000, -1.5px 1.5px 0px #000, -1.5px 0.75px 0px #000, -1.5px 0px 0px #000, -1.5px -0.75px 0px #000;

		&:before, &:after {
			.StickToEdges;
			background-size: 100% 100%;
			z-index: -1;
		}

		&:before {
			transform: translate3d(-1px,-1px,0);
			transition: transform 0.3s;
		}

		&:hover, &:focus, &.active {
			transform: translate3d(5px,5px,0);
			color: #fff;

			&:before {
				transform: translate3d(-5px,-5px,0);
			}
		}

		&:nth-of-type(8n + 1) {
			&:before {
				filter: drop-shadow(6px 6px 0 #f8ad02);
				background-image: url("@{cloudUrlNoAuto}button-right-white.svg");
			}

			&:after {
				background-image: url("@{cloudUrlNoAuto}button-yellow.svg");
			}

			+ div .category-links__child-link {
				&:before {
					filter: drop-shadow(6px 6px 0 #f8ad02);
					background-image: url("@{cloudUrlNoAuto}button-right-white.svg");
				}

				&:after {
					background-image: url("@{cloudUrlNoAuto}button-yellow.svg");
				}
			}
		}

		&:nth-of-type(8n + 2) {
			&:before {
				filter: drop-shadow(6px 6px 0 #b00068);
				background-image: url("@{cloudUrlNoAuto}button-left-white.svg");
			}

			&:after {
				background-image: url("@{cloudUrlNoAuto}button-pink.svg");
			}

			+ div .category-links__child-link {
				&:before {
					background-image: url("@{cloudUrlNoAuto}button-left-white.svg");
					filter: drop-shadow(6px 6px 0 #b00068);
				}

				&:after {
					background-image: url("@{cloudUrlNoAuto}button-pink.svg");
				}
			}
		}

		&:nth-of-type(8n + 3) {
			&:before {
				background-image: url("@{cloudUrlNoAuto}button-right-white.svg");
				filter: drop-shadow(6px 6px 0 #75003f);
			}

			&:after {
				background-image: url("@{cloudUrlNoAuto}button-purple.svg");
			}

			+ div .category-links__child-link {
				&:before {
					background-image: url("@{cloudUrlNoAuto}button-right-white.svg");
					filter: drop-shadow(6px 6px 0 #75003f);
				}

				&:after {
					background-image: url("@{cloudUrlNoAuto}button-purple.svg");
				}
			}
		}

		&:nth-of-type(8n + 4) {
			&:before {
				background-image: url("@{cloudUrlNoAuto}button-left-white.svg");
				filter: drop-shadow(6px 6px 0 #b00007);
			}

			&:after {
				background-image: url("@{cloudUrlNoAuto}button-red.svg");
			}

			+ div .category-links__child-link {
				&:before {
					background-image: url("@{cloudUrlNoAuto}button-left-white.svg");
					filter: drop-shadow(6px 6px 0 #b00007);
				}

				&:after {
					background-image: url("@{cloudUrlNoAuto}button-red.svg");
				}
			}
		}

		&:nth-of-type(8n + 5) {
			&:before {
				background-image: url("@{cloudUrlNoAuto}button-right-white.svg");
				filter: drop-shadow(6px 6px 0 #0e9323);
			}

			&:after {
				background-image: url("@{cloudUrlNoAuto}button-green.svg");
			}

			+ div .category-links__child-link {
				&:before {
					background-image: url("@{cloudUrlNoAuto}button-right-white.svg");
					filter: drop-shadow(6px 6px 0 #0e9323);
				}

				&:after {
					background-image: url("@{cloudUrlNoAuto}button-green.svg");
				}
			}
		}

		&:nth-of-type(8n + 6) {
			&:before {
				background-image: url("@{cloudUrlNoAuto}button-left-white.svg");
				filter: drop-shadow(6px 6px 0 #cd3706);
			}

			&:after {
				background-image: url("@{cloudUrlNoAuto}button-orange.svg");
			}

			+ div .category-links__child-link {
				&:before {
					background-image: url("@{cloudUrlNoAuto}button-left-white.svg");
					filter: drop-shadow(6px 6px 0 #cd3706);
				}

				&:after {
					background-image: url("@{cloudUrlNoAuto}button-orange.svg");
				}
			}
		}

		&:nth-of-type(8n + 7) {
			&:before {
				background-image: url("@{cloudUrlNoAuto}button-right-white.svg");
				filter: drop-shadow(6px 6px 0 #0069ba);
			}

			&:after {
				background-image: url("@{cloudUrlNoAuto}button-l-blue.svg");
			}

			+ div .category-links__child-link {
				&:before {
					background-image: url("@{cloudUrlNoAuto}button-right-white.svg");
					filter: drop-shadow(6px 6px 0 #0069ba);
				}

				&:after {
					background-image: url("@{cloudUrlNoAuto}button-l-blue.svg");
				}
			}
		}

		&:nth-of-type(8n + 8) {
			&:before {
				background-image: url("@{cloudUrlNoAuto}button-left-white.svg");
				filter: drop-shadow(6px 6px 0 #004b85);
			}

			&:after {
				background-image: url("@{cloudUrlNoAuto}button-d-blue.svg");
			}

			+ div .category-links__child-link {
				&:before {
					background-image: url("@{cloudUrlNoAuto}button-left-white.svg");
					filter: drop-shadow(6px 6px 0 #004b85);
				}

				&:after {
					background-image: url("@{cloudUrlNoAuto}button-d-blue.svg");
				}
			}
		}

		&:nth-child(odd) {
			margin-bottom: 18px;
			padding-top: 10px;

			&:before {
				//background-image: url("@{cloudUrlNoAuto}button-right-white.svg");
			}
		}

		&:nth-child(even) {
			margin-bottom: 8px;

			&:before {
				//background-image: url("@{cloudUrlNoAuto}button-left-white.svg");
			}
		}
	}

	&__child-link {
		margin-left: 30px;
	}
}

.badges {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 200px;

	&__badge {
		align-self: center;
		margin: 0 3px;

		&--biha {
			width: 18%;
		}

		&--tipe {
			width: 18%;
		}

		&--bcn {
			width: 45%;
		}
	}
}

.left-block {
	background-image: url("@{cloudUrl}left-block-bg.png");
	text-align: center;
	background-size: 100% 100%;
	margin-top: auto;
	padding: 0 3% 24%;

	&__logo {
		margin-top: -50%;
	}

	&__call-today {
		margin-top: 10px;
		.Bjola;
		font-size: 1.7rem;
		color: #eb008b;
		text-transform: uppercase;
	}

	&__phone, &__phone-2 {
		color: #000;
		.Bjola;
		font-size: 3rem;

		&:hover {
			color: #000;
		}
	}
}

.products-intro {
	background-color: #e5152b;
	border-radius: 15px;
	color: #fff;
	text-transform: uppercase;
	padding: 25px 34px 20px;
	line-height: 1;
	font-size: 2.11rem;
	.Bjola;
	margin: 30px 0 0;
	text-align: center;
	border: 1px;

	@media @mobile {
		position: relative;
		margin: 30px 5px;

		&:before {
			content: "";
			border: 2px dashed red;
			border-radius: 18px;
			position: absolute;
			top: -4px;
			left: -4px;
			right: -4px;
			bottom: -4px;
		}
	}

	@media @sm {
		background-color: none;
		border-radius: none;
		text-align: left;
		background-image: url("@{cloudUrlNoAuto}red-bar-bg.svg");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
}

.page-listings {
	margin-top: 30px;

	@media (max-width:420px) {
		& > div {
			width: 100%;
		}
	}
}

.castlePanel {
	width: 100%;
	margin: 0 0 30px;
	padding: 10px;
	border: none;
	border-radius: 10px;
	background: #b8e8ff;
	box-shadow: 1px 2px 0 0 #8ddaff, 2px 4px 0 0 #8ddaff, 3px 6px 0 0 #8ddaff, 4px 8px 0 0 #8ddaff, 5px 10px 0 0 #8ddaff, 6px 12px 0 0 #8ddaff, 7px 14px 0 0 #8ddaff;
	text-align: center;

	&:hover {
		.castleCheckBook {
			color: #fff000;
		}
	}

	.SingleImagePanel {
		height: auto;
	}

	.castleTitlePanel {
		margin: 10px 0 0;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			color: #084ab1;
			font-size: 1rem;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0;
		margin-top: 10px;
		color: #484646;
		font-size: 1.25rem;
		.PTSansNarrowBold;

		span.oldprice {
		}

		span.newprice {
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		background: #e1008c;
		border-radius: 10px;
		margin-top: 10px;
		height: auto;
		color: #fff;
		.OpenSansBold;
		text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.35);
		text-transform: uppercase;
		display: inline-block;
		padding: 10px 15px;
		line-height: 1;
		transition: color 0.3s;
	}
}

.testimonials {
	background-image: url("@{cloudUrlNoAuto}testimonials-bg-small.svg");
	background-size: 100% 100%;
	text-align: center;
	padding-top: 1px;
	margin-top: 30px;
	padding: 1px 5%;

	@media @mobile {
		position: relative;
		background-image: none;
		background: #ce4ec8;
		border-radius: 15px;

		&:before {
			content: "";
			border: 2px dashed #ce4ec8;
			border-radius: 18px;
			position: absolute;
			top: -4px;
			left: -4px;
			right: -4px;
			bottom: -4px;
		}
	}

	@media @desktop {
		background-image: url("@{cloudUrl}testimonials-faces-bg.png");
		padding-left: 42%;
		padding-right: 8%;
	}

	&__title {
		color: #fff;
		text-transform: uppercase;
		font-size: 2.11rem;
		.Bjola;

		@media @desktop {
			margin-top: 40px;
		}
	}

	&__inner {
		color: #fff;
		font-size: 1rem;
		margin-bottom: 20px;

		@media @desktop {
			margin-top: 20px;
		}

		a {
			color: #fff;

			&:hover {
				color: #fff;
			}
		}
	}

	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;

		p:first-child {
			.PTSansNarrowBold;
			font-size: 1.2rem;
		}
	}

	#RotatorTestimonialsHeader {
		margin-bottom: 15px;

		p, a {
			text-align: center;
			color: #fff;
			font-size: 1rem;
			position: relative;
		}
	}

	.RotatorTestimonialRating {
		max-width: 280px;
		margin: 20px auto 0;
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.DetailsTitle {
	h1, h2 {
		padding: 10px;
		.Bjola;
		text-transform: uppercase;
		font-size: 1.8rem;
		color: #fff;
		text-shadow: 0px 0px 0px #000, -0.75px -0.75px 0px #000, 0px -0.75px 0px #000, 0.75px -0.75px 0px #000, 0.75px 0px 0px #000, 0.75px 0.75px 0px #000, 0px 0.75px 0px #000, -0.75px 0.75px 0px #000, -0.75px 0px 0px #000, -1.5px -1.5px 0px #000, -0.75px -1.5px 0px #000, 0px -1.5px 0px #000, 0.75px -1.5px 0px #000, 1.5px -1.5px 0px #000, 1.5px -0.75px 0px #000, 1.5px 0px 0px #000, 1.5px 0.75px 0px #000, 1.5px 1.5px 0px #000, 0.75px 1.5px 0px #000, 0px 1.5px 0px #000, -0.75px 1.5px 0px #000, -1.5px 1.5px 0px #000, -1.5px 0.75px 0px #000, -1.5px 0px 0px #000, -1.5px -0.75px 0px #000;
	}
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 180px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}

.footer {
	padding: 20px 0 50px;
	color: #222;

	&__container {
		background-color: rgba(255,255,255,0.5);
		padding: 10px;
		border-radius: 10px;
	}

	&__bg {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	@media @mobile {
		text-align: center;
	}

	&__flex-row-sm {
		@media @sm {
			display: flex;
			align-items: center;
		}
	}

	&__text-center-sm {
		@media @sm {
			text-align: center;
		}
	}

	&__text-right-sm {
		@media @sm {
			text-align: right;
		}
	}

	&__links {
		text-align: center;
	}

	&__link {
		margin: 0 5px 10px;
		padding: 8px 0;
		color: #222;
		display: inline-block;

		&:hover {
			color: #222;
		}
	}

	#BCNLink {
		color: #222;

		&:hover {
			color: #222;
		}
	}
}
/*#region Animations*/

/*#endregion Animations*/
